#didomi-host .didomi-buttons .didomi-button {
  position: relative;
  overflow: hidden;
  min-height: 2rem;
  @include animate;

  span {
    @include text-s-bold-uppercase-cta;
  }
}

#didomi-host .didomi-buttons .didomi-button-highlight,
#didomi-host .didomi-buttons .didomi-button-standard,
#didomi-host #didomi-notice #buttons.didomi-buttons #didomi-notice-agree-button,
#didomi-host #didomi-notice #buttons.didomi-buttons #didomi-notice-do-not-sell-button {
  @include text-s-bold-uppercase-cta;
  height: 38px;
  color: $white !important;
  background: linear-gradient(0deg, $white 50%, $dark 50%);
  background-size: 100% 200%;
  transition: background-position-y 300ms ease-out, box-shadow 300ms ease-out, color 50ms ease-out 150ms;
  span {
    color: $white;
  }

  &:focus-visible {
    @include button-shadow;
    background-position-y: 100%;
    color: $dark !important;
    span {
      color: $dark;
    }
  }
  @media (any-hover: hover) {
    &:hover {
      @include button-shadow;
      background-position-y: 100%;
      color: $dark !important;
      span {
        color: $dark;
      }
    }
  }
}

#didomi-host #didomi-notice .didomi-buttons .didomi-button-standard {
  @include text-s-bold-uppercase-cta;
  height: 38px;
  color: $dark !important;
  background: linear-gradient(0deg, $dark 50%, $white 50%);
  background-size: 100% 200%;
  transition: background-position-y 300ms ease-out, box-shadow 300ms ease-out, color 50ms ease-out 150ms;
  @include button-shadow;
  span {
    color: $dark;
  }

  &:focus-visible {
    box-shadow: initial;
    background-position-y: 100%;
    color: $white !important;
    span {
      color: $white;
    }
  }
  @media (any-hover: hover) {
    &:hover {
      box-shadow: initial;
      background-position-y: 100%;
      color: $white !important;
      span {
        color: $white;
      }
    }
  }
}

#didomi-host #didomi-notice.didomi-regular-notice.shape-banner .didomi-notice__interior-border.didomi-border {
  padding: 0;
  flex-direction: column;
  gap: 16px;
}

#didomi-host #didomi-notice.didomi-regular-notice.top.didomi-notice-banner {
  margin: 0 16px;
  padding: 32px 16px 24px 16px;
  bottom: unset;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  @include rounded-12;
  @include container-shadow;
  backdrop-filter: blur(10px);
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.65) 100%) !important;

  .didomi-notice-text {
    margin: 16px 0 0;
  }

  @include sm-desktop {
    margin: 0;
    grid-column: 4 / span 6;
    position: absolute;
    @include button-shadow;
  }
}

#didomi-host .didomi-mobile #didomi-notice.didomi-regular-notice.top .didomi-notice__interior-border.didomi-border,
#didomi-host .didomi-mobile #didomi-notice.didomi-regular-notice.top.didomi-regular-notice-with-data-processing,
#didomi-host .didomi-consent-popup-preferences-vendors .didomi-content .didomi-user-information-container,
#didomi-host
  .didomi-consent-popup-preferences-vendors
  .didomi-consent-popup-body__section
  .didomi-consent-popup-body_vendors-list {
  border: 0;
}

#didomi-host #didomi-notice.didomi-regular-notice.shape-banner #buttons.multiple {
  margin: 0;
  width: 100%;
  flex-direction: row !important;
  gap: 16px;

  .didomi-components-button {
    margin: 0;
    width: 100%;
    padding-inline: 10px;
    white-space: unset;
    min-width: auto;
  }
}

div#didomi-host {
  width: 100%;
  position: fixed;
  z-index: 25;
  @include white-gradient;

  > div {
    @include margins;
    @include grid;
    position: relative;
    height: 100%;

    @include sm-desktop {
      column-gap: 0;
    }
  }

  p:not(.didomi-buttons .didomi-button span),
  span:not(.didomi-buttons .didomi-button span),
  a:not(.didomi-buttons .didomi-button span) {
    color: $dark;
    font-family: inherit;
    font-size: 0.75rem;
    @include ltr-only {
      letter-spacing: -0.02em;
    }
    line-height: 140%;
    text-underline-offset: 2.5px;
  }

  .didomi-consent-popup-header.didomi-popup-header {
    font-family: inherit;
  }

  .didomi-consent-popup-header.didomi-popup-header .didomi-popup-close.didomi-consent-popup-header-close,
  .didomi-popup-view.didomi-consent-popup-preferences-purposes .didomi-popup-close.didomi-consent-popup-header-close {
    opacity: 1;
    transform: scale(2.5);
    width: 16px;
    height: 20px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    transition: unset;
  }
}

#didomi-host #didomi-consent-popup {
  position: fixed;
  margin: 0;
  max-width: unset;
  visibility: visible;
}

#didomi-host .didomi-exterior-border.didomi-consent-popup__exterior-border {
  grid-column: span 12;
  margin: 12px 8px;
  border: 0;
  width: calc(100% - 16px);

  @include sm-desktop {
    margin: 24px 32px;
    width: calc(100% - 64px);
  }
}

#didomi-host .didomi-popup-container.didomi-consent-popup__dialog {
  background: $white;
  max-width: 100%;
  border: 0;
  @include rounded-8;
  @include button-shadow;
}

#didomi-host .didomi-consent-popup-footer.didomi-popup-footer {
  @include rounded-8;
}

#didomi-host .didomi-consent-popup-header.didomi-popup-header {
  padding: 16px 16px 0;
  .didomi-consent-popup-title {
    span {
      font-size: 0.875rem;
      line-height: 100%;
      cursor: auto;
    }
    a span {
      cursor: pointer;
    }
  }
}

#didomi-host .didomi-popup-view.didomi-consent-popup-preferences-vendors .didomi-consent-popup-body,
#didomi-host .didomi-consent-popup-body.didomi-popup-body {
  padding: 16px 16px 0;
}
#didomi-host .didomi-consent-popup-footer.didomi-popup-footer {
  padding: 0 8px 8px;
}

#didomi-host .didomi-consent-popup-body.didomi-popup-body .didomi-consent-popup-body__explanation span {
  font-size: 0.875rem;
  line-height: 100%;
  margin-bottom: 24px;
}

#didomi-host .didomi-components-accordion .didomi-consent-popup-data-processing__description.didomi-content {
  font-weight: $font-weight-regular;
}

#didomi-host
  .didomi-consent-popup-preferences-purposes
  .didomi-consent-popup-data-processing__description_legal_icon:focus::after,
#didomi-host .parent-feature-and-special-purpose .feature-and-special-purpose span::after,
#didomi-host .didomi-consent-popup-vendor__description.didomi-content.active span::after {
  padding: 8px 12px;
  @include rounded-8;
  background: $white;
  @include container-shadow;
  backdrop-filter: blur(10px);
  border: 0;
  @include text-xxs-regular;
}

#didomi-host .didomi-consent-popup-footer .didomi-consent-popup-actions .didomi-consent-popup-information-save,
#didomi-host .didomi-notice-banner .didomi-notice-view-partners-link,
#didomi-host div.didomi-notice-data-processing-container {
  display: none;
}

#didomi-host .didomi-consent-popup-preferences .didomi-consent-popup-body__section .didomi-consent-popup-purpose {
  margin: 25px 0 0;
}

#didomi-host .didomi-consent-popup-body_vendors-list .didomi-components-radio .didomi-components-radio__option,
#didomi-host .didomi-consent-popup-body__section.didomi-popup-body-section .didomi-components-radio__option {
  border: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 4px;
  background: $white;
  font-weight: $font-weight-regular;
  box-shadow: unset;
  @include text-underline;

  &.didomi-components-radio__option--agree {
    svg {
      stroke: $success;
      margin: 0;
    }
    span {
      color: $success;
    }
  }

  &.didomi-components-radio__option--disagree {
    polygon {
      fill: $error;
    }
    span {
      color: $error;
    }
  }
}

#didomi-host
  .didomi-consent-popup-preferences-vendors
  .didomi-consent-popup-body__section
  .didomi-consent-popup-container-click-all {
  background: $very-light-gray;
}
#didomi-host .didomi-consent-popup-preferences-vendors .didomi-consent-popup-body__section {
  @include rounded-8;
  overflow: hidden;
  border: 1px solid $very-light-gray;
}

#didomi-host
  .didomi-consent-popup-vendor.didomi-consent-popup-container-click-all
  .didomi-components-radio
  .didomi-components-radio__option {
  padding: 8px 16px;
  border: 0;
  @include rounded-8;
  background: $white;
  @include button-shadow;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: none;
  }
  span {
    font-size: 0.875rem;
    line-height: 100%;
  }

  &.didomi-components-radio__option--agree {
    span {
      color: $success;
    }
  }
  &.didomi-components-radio__option--disagree {
    span {
      color: $error;
    }
  }
}

#didomi-host #didomi-notice-x-button {
  background-color: transparent !important;
  top: 0 !important;
  inset-inline-end: 16px !important;
  width: auto;
  border-radius: unset;

  p {
    @include text-xxs-regular;
    @include text-underline;
  }

  svg {
    display: none;
  }
}

#didomi-host
  .didomi-consent-popup-preferences-purposes
  .didomi-consent-popup-body
  .didomi-consent-popup-view-vendors-list
  .didomi-consent-popup-view-vendors-list-link {
  border: 0;
  background: $white;
  font-weight: $font-weight-regular;
  box-shadow: unset;
  @include text-underline;
}

div#didomi-host a,
#didomi-host .didomi-buttons .didomi-button-highlight,
#didomi-host .didomi-buttons .didomi-button-standard,
#didomi-host #didomi-notice-x-button,
#didomi-host .didomi-consent-popup-body_vendors-list .didomi-components-radio .didomi-components-radio__option,
#didomi-host .didomi-consent-popup-body__section.didomi-popup-body-section .didomi-components-radio__option,
#didomi-host .label-click,
#didomi-host .didomi-consent-popup-view-vendors-list-link {
  &:focus-visible {
    outline: 1px auto $blue;
  }
}

#didomi-host #didomi-notice .didomi-notice-title {
  @include text-m-regular;
  margin-bottom: 16px;
}

//didomi gpc container
#didomi-host .didomi-screen-xsmall #didomi-notice.didomi-regular-notice.didomi-notice-banner .didomi-gpc-container {
  .didomi-gpc-label {
    width: 280px;

    .left-container {
      width: 150px;
      .gpc-image {
        width: 132px;
        height: 14px;
        margin-top: 5px;
        margin-inline-start: 10px;
      }
    }
  }
}

#didomi-host #didomi-notice.didomi-regular-notice.didomi-notice-banner .didomi-gpc-container {
  align-items: center;

  .didomi-gpc-label {
    display: flex;
    align-items: center;
    height: 27px;
    .right-container {
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .gpc-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: auto;
        position: static;
        margin: auto 0;
        margin-inline-start: 4px;
      }
      .gpc-icon {
        position: static;
      }
    }
    .left-container {
      height: 100%;
    }
  }
}

// US override

#didomi-notice > h1.didomi-title-notice {
  margin-left: 0 !important;
}

[data-overlay="false"]#didomi-host #didomi-notice.didomi-regular-notice.top.didomi-notice-banner {
  top: 8px;
  transform: none;
  background: $white !important;

  @include sm-desktop {
    grid-column: 8 / span 5;
    transform: translateY(var(--header-menu));
    top: unset;
    bottom: unset;
    transition: transform 0.3s ease;
    inset-inline-start: auto !important;
    inset-inline-end: 0 !important;
  }
  @include md-desktop {
    grid-column: 9 / span 4;
  }
}

//remove backdrop-filter if button "Cookie Management" is clicked
body.didomi-popup-open #didomi-host {
  backdrop-filter: unset !important;
}

//override banner button colors
#didomi-host[data-second-button-color="white"] #didomi-notice #buttons.didomi-buttons #didomi-notice-agree-button,
#didomi-host[data-first-button-color="white"] #didomi-notice #buttons.didomi-buttons #didomi-notice-do-not-sell-button {
  color: $dark !important;
  @include button-shadow;
  background: linear-gradient(0deg, $dark 50%, $white 50%);
  background-size: 100% 200%;
  span {
    color: $dark;
  }

  &:focus-visible {
    background-position-y: 100%;
    color: $white !important;
    span {
      color: $white;
    }
  }
  @media (any-hover: hover) {
    &:hover {
      background-position-y: 100%;
      color: $white !important;
      span {
        color: $white;
      }
    }
  }
}

#didomi-host[data-first-button-color="black"] #didomi-notice #buttons.didomi-buttons #didomi-notice-learn-more-button {
  color: $white !important;
  background: linear-gradient(0deg, $white 50%, $dark 50%);
  background-size: 100% 200%;
  span {
    color: $white;
  }

  &:focus-visible {
    background-position-y: 100%;
    color: $dark !important;
    @include button-shadow;
    span {
      color: $dark;
    }
  }
  @media (any-hover: hover) {
    &:hover {
      background-position-y: 100%;
      color: $dark !important;
      @include button-shadow;
      span {
        color: $dark;
      }
    }
  }
}

//override banner buttons position

#didomi-host[data-buttons-in-column-mobile="true"] #didomi-notice.didomi-regular-notice.shape-banner #buttons.multiple {
  @include mobile-only {
    flex-direction: column !important;
  }
}

#didomi-host[data-buttons-in-column-desktop="true"]
  #didomi-notice.didomi-regular-notice.shape-banner
  #buttons.multiple {
  @include sm-desktop {
    flex-direction: column !important;
  }
}

// hide/show didomi notice
[data-visible="false"]#didomi-host {
  visibility: hidden;
}

[data-visible="true"]#didomi-host {
  visibility: visible;
}
