@use "styles" as *;

div.overlay {
  z-index: 45;
}

div.countryWrapperDialog {
  --wrapper-maxWidth: calc(360px + var(--wrapper-inset-horizontal) * 2);
  --wrapper-alignItems: flex-start;

  @include sm-desktop {
    --wrapper-alignItems: center;
  }
}

.wrapperTitle {
  display: flex;
  align-items: center;
  gap: 12px;
}

.title {
  width: 240px;
}

.updateCountry {
  margin-bottom: 24px;
  @include text-s-regular;
}

.highlightedCountry {
  @include text-s-bold;
}

.wrapperCTA {
  margin-bottom: 8px;
  padding: 8px 16px;
  @include rounded-8;
  width: 100%;
  text-align: unset;
  display: flex;
  align-items: center;
  gap: 12px;
  @include glass-gradient;
  @include container-shadow;

  &:hover,
  &:focus-visible {
    .flag {
      border: 1px solid $dark;
    }
  }
}

.wrapperAnswer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.answer {
  @include text-xxs-bold-uppercase;
}

.paragraph {
  @include text-xs-regular;
}

.icon {
  padding: 4px;
}

.flag {
  padding: 3px;
  border: 1px solid $light-gray;
  @include rounded-20;
}
