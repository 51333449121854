@use "styles" as *;

.button {
  @include text-s-bold-uppercase-cta;
  @include animate;

  position: relative;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  overflow: hidden;
  color: $white;
  min-height: 2rem;
  &:disabled {
    cursor: initial;
    &:not(.loading) {
      opacity: 0.5;
    }
  }
}
.primary {
  background: linear-gradient(0deg, $white 50%, $dark 50%);
  background-size: 100% 200%;
  transition: background-position-y 300ms ease-out, box-shadow 300ms ease-out, color 50ms ease-out 150ms;
  &:focus,
  &.loading {
    color: $dark;
    @include button-shadow;
    background-position-y: 100%;
  }
  &:not(:disabled) {
    @media (any-hover: hover) {
      &:hover {
        color: $dark;
        @include button-shadow;
        background-position-y: 100%;
      }
    }
  }
}

.secondary {
  color: $dark;
  background: linear-gradient(0deg, $dark 50%, $white 50%);
  background-size: 100% 200%;
  @include button-shadow;
  transition: background-position-y 300ms ease-out, box-shadow 300ms ease-out, color 50ms ease-out 100ms;
  &:focus,
  &.loading {
    box-shadow: initial;
    color: $white;
    background-position-y: 100%;
  }
  &.loading {
    .loadingWrapper {
      background-color: $dark;
    }
    .loadingDots {
      background: $white;
    }
  }
  &:not(:disabled) {
    @media (any-hover: hover) {
      &:hover {
        box-shadow: initial;
        color: $white;
        background-position-y: 100%;
      }
    }
  }
}

.serviceBig,
.serviceMedium,
.serviceSmall {
  color: $dark;
  @include button-shadow;
  background: $white;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  &:focus {
    .iconWrapper {
      background: $dark;
      color: $white;
    }
  }
  &:not(:disabled) {
    @media (any-hover: hover) {
      &:hover {
        .iconWrapper {
          background: $dark;
          color: $white;
        }
      }
    }
  }
}

.serviceBig {
  border-radius: 12px;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  min-width: 190px;
  min-height: 48px;
  @include text-s-bold-uppercase;
}

.serviceMedium {
  border-radius: 8px;
  padding: 6px 6px 6px 12px;
  min-width: 144px;
  min-height: 36px;
  @include text-xs-bold-uppercase;
}

.serviceSmall {
  min-height: 24px;
  @include text-xxs-bold-uppercase;
}

.fullWidth {
  width: 100%;
  display: block;
  text-align: center;
}

.disabled {
  cursor: initial;
  &:not(.loading) {
    opacity: 0.5;
  }
}

.iconWrapper {
  display: flex;
  height: 24px;
  width: 24px;
  background: transparent;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: $dark;
}

.linkButton {
  text-decoration: none;
}

.loadingWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  background-color: $white;
}

.loadingDots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $dark;
}
