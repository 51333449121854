@use "styles" as *;

.footer {
  @include glass-shadow;
}

.socialLinksContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;
  row-gap: 8px;
  flex-wrap: wrap;
  margin: 24px 0;
  @include text-xs-bold-uppercase;

  .followUsOnLabel {
    width: 100%;
    text-align: center;
    @include sm-desktop {
      width: auto;
    }
  }
}

.legalContainer {
  grid-column: 1 / span 12;
  padding: 8px 32px 12px;
  background-color: $dark;

  @include sm-desktop {
    padding: 8px auto;
  }
}

.legalText {
  @include text-3xs-regular;
  text-align: center;
  color: $white;
}

.media {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 32px;
}

.mediaApple {
  position: relative;
  height: 32px;
  width: 95px;
}

.mediaGoogle {
  position: relative;
  height: 32px;
  width: 108px;
}
