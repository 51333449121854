@use "styles" as *;

.container {
  @include text-xs-regular-uppercase;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  height: 48px;
  width: 100%;
  color: $white;
  background: rgba(255, 0, 0, 0.8);

  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  opacity: 1;
  transition: all 300ms ease-out;

  &.hideBar {
    height: 0;
    opacity: 0;
  }
}
