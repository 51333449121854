@use "styles" as *;

.pagePopup {
  inset-block-start: 45px !important;

  @include sm-desktop {
    inset-block-start: $header-desktop - $header-nav !important;
  }
}

.panel {
  width: 100%;

  @include sm-desktop() {
    width: 410px;
  }
}

.panelInfo {
  border: 0 none;
}

.panelError {
  border: 1px solid $error;
  color: $error;
}

.panelSuccess {
  border: 1px solid $success;
  color: $success;
}

.GlobalNotificationContainer {
  display: grid;
  grid-template-columns: 16px 1fr 20px;
  grid-template-rows: auto auto auto;
  gap: 8px 3px;
  grid-template-areas:
    "Heading Heading Heading"
    ". Description ."
    "Prompt Prompt Prompt";
}

.GlobalNotificationTitleContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: stretch;
  align-items: flex-start;
  gap: 3px;
  grid-area: Heading;
}

.GlobalNotificationTitle {
  width: calc(100% - 20px);
  @include text-xs-bold-uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: currentColor;
}

.GlobalNotificationDescriptionContainer {
  grid-area: Description;
}

.GlobalNotificationDescription {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  color: currentColor;

  &--error {
    color: $error;
  }

  @include text-xs-regular;
  line-height: 14px;
  overflow: hidden;

  p {
    padding-block-end: 16px;
  }

  ul {
    display: flex;
    padding-block-end: 16px;
    max-width: 100%;
    flex-direction: row;
    column-gap: 32px;
    row-gap: 12px;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;

    li {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;

      span {
        font-weight: $font-weight-bold;
      }
    }
  }
}

.GlobalNotificationPromptContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  grid-area: Prompt;
  margin-inline: 8px;

  @include sm-desktop() {
    flex-direction: row;
  }
}

.GlobalNotificationLink {
  margin-inline-start: 0;
  margin-inline-end: auto;
}

.GlobalNotificationButton {
  margin-inline-start: auto;
  margin-inline-end: 0;
}
