@use "styles" as *;

:root {
  --fromRGBA: rgba(0, 0, 0, 0.15);
  // --fromRGBA: #e2233226;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  max-width: 100vw;
  // avoid this, break sticky
  // overflow-x: hidden;

  color: $dark;
  font-family: var(--local-font);
  font-weight: $font-weight-regular;
}

body {
  display: flex;
  flex-direction: column;
}

#__next {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  main {
    flex-grow: 1;
  }
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  appearance: initial;
  background: initial;
  border: initial;
  cursor: pointer;
  color: inherit;

  font-family: var(--local-font);
}

input,
textarea {
  // appearance: initial;
  // background: initial;
  // border: initial;
  color: initial;

  font-family: var(--local-font);
}

// fot test purposes
hr {
  width: 100%;
  margin: 1rem 0;
  border-style: dashed;
}

header ~ main {
  margin-top: $header-mobile;
  @include sm-desktop {
    margin-top: $header-desktop;
  }

  &.contentBelowHeader {
    margin-top: $header-topbar-mobile;
    @include sm-desktop {
      margin-top: $header-topbar-desktop;
    }
  }
}

// for mirroring icons
.iconRtl {
  .iconsPage & {
    outline: 1px solid $error;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
  // to avoid the layout shift on desktop while applying overflow hidden due to the page scrollbar
  @include sm-desktop() {
    padding-right: var(--scrollbar-width);
    header {
      padding-right: var(--scrollbar-width);
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.ReactModal__Overlay--after-open {
    opacity: 1;

    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }
}

//override scss date picker
@import "node_modules/react-datepicker/src/stylesheets/datepicker.scss";

.react-datepicker__header {
  padding: 0;

  &:not(.react-datepicker__header--has-time-select) {
    border-radius: 0;
    border-color: $light-gray;
    background: transparent;
  }
}

.react-datepicker__day {
  width: 100%;
  margin: 0;

  &:hover,
  &:focus-visible {
    @include rounded-4;
    @include color-gradient;
  }

  &--keyboard-selected {
    @include rounded-4;
    @include color-gradient;
  }

  &-name {
    width: 100%;
    margin: 0;
  }

  &-names {
    padding: 10px 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 12px;
    justify-content: center;
    border-color: $light-gray;
    @include text-xxs-bold-uppercase;
  }

  &--selected {
    @include rounded-4;
    background-color: $white;
    color: $dark;
    @include button-shadow;
  }
}

.react-datepicker__week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 12px;
  justify-content: center;
}

.react-datepicker__month {
  margin: 0;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &-container {
    padding: 16px;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  position: absolute;
  z-index: 10;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  fill: $white;
  color: $white;
}

// TEST progress bar
#nprogress {
  [role="bar"].bar {
    @include kisses-gradient;

    .peg {
      display: none;
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

// snippet from https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

// Avoid screen zoom-in on iPhone when focusing a text field
// https://stackoverflow.com/a/73558386/1886206
@media only screen and (hover: none) and (pointer: coarse) {
  input,
  select,
  textarea {
    font-size: 0.875rem !important;
  }

  input:focus,
  select:focus,
  textarea:focus {
    font-size: 1rem !important;
  }
}

//Power reviews style
@import "/src/components/common/powerreviews-styles-override/powerreviews-styles-override.scss";

//Didomi style
@import "/src/components/common/didomi-styles-override/didomi-styles-override";

// AUTOCOMPLETE INPUT
.localities-container {
  @include rounded-8;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 1px;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  list-style-type: none;
  margin: 0;
  z-index: 101;
  margin-top: 1px;
  margin-left: -1px;
  // border: 1px solid transparent;
  box-sizing: content-box;

  &.localities-hidden {
    display: none;
    pointer-events: none;
  }
}

.localities-input-wrapper {
  width: 100%;
}

.localities-item {
  @include text-s-regular;
  padding: 16px 10px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:not(:last-child) {
    border-bottom: 1px solid $light-gray;
  }
}

.localities-item-query {
  cursor: pointer;
  @include text-s-bold;
}

.localities-item-selected,
.localities-item:hover {
  text-decoration: underline;
}

.localities-item-selected .localities-icon,
.localities-item:hover .localities-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='26' viewBox='0 0 14 26'%3E%3Cg fill='none'%3E%3Cpath fill='%23ffc928' d='M6.90186,25.2777778 C7.07440222,21.7953011 8.92297,16.13906 11.6021967,12.5791167 C13.0618872,10.6393933 13.7334867,8.74895 13.7334867,7.60206611 C13.7334867,3.61842444 10.6590789,0.388888889 6.86682889,0.388888889 L6.86665,0.388888889 C3.07422111,0.388888889 -7.77777778e-06,3.61842444 -7.77777778e-06,7.60206611 C-7.77777778e-06,8.74895 0.671591667,10.6393933 2.13128222,12.5791167 C4.81050889,16.13906 6.65890011,21.7953011 6.83161889,25.2777778 L6.90186,25.2777778 Z'/%3E%3Ccircle cx='6.774' cy='7.609' r='2.472' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-color: transparent;
}

.localities-icon {
  top: 5px;
  height: 23px;
  background-color: transparent;
  -webkit-mask-image: unset;
  mask-image: unset;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='26' viewBox='0 0 14 26'%3E%3Cg fill='none'%3E%3Cpath fill='%23b2b2b2' d='M6.90186,25.2777778 C7.07440222,21.7953011 8.92297,16.13906 11.6021967,12.5791167 C13.0618872,10.6393933 13.7334867,8.74895 13.7334867,7.60206611 C13.7334867,3.61842444 10.6590789,0.388888889 6.86682889,0.388888889 L6.86665,0.388888889 C3.07422111,0.388888889 -7.77777778e-06,3.61842444 -7.77777778e-06,7.60206611 C-7.77777778e-06,8.74895 0.671591667,10.6393933 2.13128222,12.5791167 C4.81050889,16.13906 6.65890011,21.7953011 6.83161889,25.2777778 L6.90186,25.2777778 Z'/%3E%3Ccircle cx='6.774' cy='7.609' r='2.472' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E%0A");
}

#st-faq {
  position: relative;
  z-index: 1;
}

//remove container css rules if used in a grid
.EditorialElementPLPContainer {
  .containerComponent {
    margin-inline-start: auto;
    margin-inline-end: auto;

    @include lg-desktop {
      min-width: auto;
    }
  }
}

.kiko-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(13deg, #888 14%, #999 64%);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(13deg, #999 14%, #aaa 64%);
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #f0f0f0;
  }
}

#revieve-target {
  display: none;
  cursor: none;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 16px;
  overflow: hidden;
  @include sm-desktop {
    height: calc(100% - 80px);
    width: calc(100% - 64px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.show {
    display: block;
    z-index: 3;
  }
}
// override adyen box width mobile
#adyen-dropin {
  & > *,
  & iframe {
    overflow: hidden;
    width: 100%;
  }
}

// decrease z-indexes of overlaying elements to better see modal content (especially on mobile)
#usntA40Toggle {
  z-index: 49 !important;
  inset-inline-start: 12px !important;
  inset-block-end: 8px !important;

  @include sm-desktop {
    inset-inline-start: 32px !important;
    inset-block-end: 16px !important;
  }
}

.swiper-wrapper {
  //transition-duration is managed by Swiper speed prop (default is 300ms)
  --swiper-wrapper-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

// override autocomplete theme algolia
.aa-Panel {
  z-index: 101;
}

.d-none {
  display: none;
}

// mui override
.Mui-selected {
  background-color: #d63868 !important;
}
@media (any-hover: hover) {
  .MuiButtonBase-root:hover {
    background-color: #fff5f5 !important;
  }
}

//remove comments if you want to put skeleton backgrounds
// .backgroundGradientLight {
//   @include color-gradient-light-opacity;
// }

// .backgroundVeryLightGray {
//   background: $very-light-gray-opacity;
// }

// .backgroundLightGray {
//   background: $light-gray-opacity;
// }
