@use "styles" as *;

.link {
  color: $dark;
  font-weight: $font-weight-regular;
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
  gap: 4px;
  // added to compensate the offset of the underline in the Figmas
  text-underline-offset: 0.14rem;
  &.linkSmall {
    line-height: 0.625rem;
    @include text-xxs-regular;
  }
  &.linkMedium {
    line-height: 1rem;
    text-underline-offset: 0.18rem;
    @include text-xs-regular;
    // min-height: 18px;
  }
  .smallIcon {
    width: 16px;
    height: 16px;
  }
}
