@use "breakpoints";
@use "container";

@mixin grid {
  display: grid;
  column-gap: 1px;
  grid-template-columns: repeat(12, 1fr);

  @include breakpoints.tablet {
    column-gap: 12px;
  }
}
