@use "styles" as *;

// common containers

.overlay {
  position: fixed;
  inset: 0;
  z-index: 100;
  &.hasBackdrop {
    @include glass-gradient;
  }
}

.wrapper {
  --wrapper-inset-vertical: 12px;
  --wrapper-inset-horizontal: 8px;
  --wrapper-maxWidth: #{$maxWidth};
  --wrapper-alignItems: flex-end;

  // position: fixed;
  // inset: 0;
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: var(--wrapper-alignItems);
  max-width: var(--wrapper-maxWidth);

  @include tablet {
    --wrapper-inset-vertical: 16px;
    --wrapper-inset-horizontal: 12px;
    --wrapper-alignItems: center;
  }
  @include sm-desktop {
    --wrapper-alignItems: flex-start;
  }
  @include md-desktop {
    --wrapper-inset-horizontal: 32px;
  }
}

.panel {
  --panel-gap: 12px;

  display: flex;
  flex-direction: column;
  gap: var(--panel-gap);
  width: 100%;
  max-height: 100%;
  padding: var(--wrapper-inset-vertical) var(--wrapper-inset-horizontal);
  overflow-y: auto;
  overflow-x: hidden;

  @include md-desktop {
    --panel-gap: 16px;
  }
}

.content {
  --content-padding-vertical: 12px;
  --content-padding-horizontal: 16px;
  --content-gap: 8px;

  display: flex;
  flex-direction: column;
  gap: var(--content-gap);
  @include button-shadow;
  @include rounded-8;
  padding: var(--content-padding-vertical) var(--content-padding-horizontal);
  background-color: $white;

  @include sm-desktop {
    --content-gap: 12px;
  }

  @include md-desktop {
    --content-gap: 16px;
  }
}

// shared elements

.top {
  --top-gap: 12px;

  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  gap: var(--top-gap);

  @include md-desktop {
    --top-gap: 16px;
  }
}

.header {
  --header-padding-vertical: 10px;
  --header-padding-horizontal: 16px;
  --header-gap: 12px;

  @include button-shadow;
  @include rounded-8;

  display: flex;
  align-items: center;
  padding: var(--header-padding-vertical) var(--header-padding-horizontal);
  gap: var(--header-gap);
  background-color: $white;
  flex: 1;
}

.title {
  --title-gap: 12px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--title-gap);
  @include text-s-bold-uppercase;
}

.close {
  --close-padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  @include button-shadow;
  @include rounded-8;
  padding: var(--close-padding);
  background-color: $white;
  width: auto;
  height: 100%;
  aspect-ratio: 1 / 1;
}
