@use "styles" as *;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  background: $white;
  width: 100%;
  @include glass-shadow;
  height: $header-main;
}

.center {
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  text-align: center;
  padding-block-end: 24px;
}

.grid {
  width: 100%;
  row-gap: 2rem;
  margin-block-end: 24px;

  @include sm-desktop {
    align-items: center;
  }
}

.column1 {
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 12;
  gap: 2rem;
  text-align: center;

  @include sm-desktop {
    grid-column: 1 / span 5;
    margin: 0 24px;
  }
}

.column2 {
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 12;
  gap: 1rem;

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @include sm-desktop {
    grid-column: 6 / span 7;
    padding: 0 32px;
  }
}

.heading {
  @include text-3xl-extralight;

  strong {
    @include text-3xl-semibold;
  }
}

.description {
  @include text-m-regular;
}

//customer care
.container {
  @include text-xs-regular;
  text-align: center;
  margin: 14px;
}

.gradient {
  @include text-xs-highlighted;
  line-height: 140%;
}

.link {
  @include text-xs-regular;
  @include text-underline;
  line-height: 140%;
  display: inline-block;
  margin-top: 4px;
  @include sm-desktop {
    display: initial;
    margin-top: initial;
  }
}
