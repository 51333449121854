//Power reviews style

//Power review Header
#pr-reviewdisplay #pr-review-snapshot {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 32px;

  @include sm-desktop {
    display: grid;
    grid-template-columns: 0 repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center;
    gap: 12px;
    margin-inline-start: -12px;
  }

  .pr-review-snapshot-block-container {
    margin: 0;
  }

  &::before,
  &::after {
    content: none;
  }

  //header first block
  .pr-review-snapshot-header.pr-review-snapshot-content-block {
    padding: 16px 16px 24px;
    margin: 0;
    @include container-shadow;
    background: $white;
    @include rounded-12;
    flex: 1;

    @include sm-desktop {
      padding: 20px 16px;
      display: grid;
      align-items: center;
    }

    .pr-snippet-stars-reco-inline {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      .pr-snippet-stars-reco-reco {
        margin: 0;
      }

      .pr-snippet-reco-to-friend {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        margin-bottom: 24px;

        .pr-snippet-reco-to-friend-percent {
          background: transparent;
          .pr-reco {
            padding: 0;

            .pr-reco-value {
              margin: 0;
              @include text-xl-semibold;
              color: $dark;
            }
          }
        }
        .pr-reco-to-friend-message {
          margin: 0;
          padding: 0;
          @include text-xs-regular-uppercase;
          text-align: center;
          max-width: unset;
        }
      }

      .pr-snippet-stars-reco-stars .pr-snippet {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;

        .pr-snippet-read-and-write {
          margin: 0;
          .pr-snippet-review-count {
            padding: 0;
            margin: 0;
            color: $dark;
            @include text-xs-bold-uppercase;
            font-size: 0.75rem !important;
            font-style: normal;
            text-align: center;
          }
        }
      }
    }
  }

  //header second block
  .pr-review-snapshot-block-histogram {
    margin: 0;
    padding: 0;
    width: 100%;
    flex: 1;

    @include sm-desktop {
      margin: auto 0;
      padding: 20px 16px;
      width: 100%;
      height: 100%;
      display: grid;
      align-items: center;
    }

    .pr-review-snapshot-block-headline {
      padding: 0;
      text-align: center;
      @include text-xs-regular-uppercase;
      font-weight: $font-weight-regular;
    }

    .pr-histogram-cross {
      display: none;
    }

    .pr-ratings-histogram-bar {
      background: transparent;
      height: 8px;
      box-shadow: 0 0 0 0.5px $fucsia2;
      overflow: hidden;
    }

    .pr-ratings-histogram-barValue {
      @include kisses-gradient;
      height: 100%;
      border: none;
    }

    .pr-histogram-label,
    .pr-histogram-count {
      padding: 0;
      @include text-xs-regular-uppercase;
      color: $dark;
      font-weight: $font-weight-regular;
    }
    .pr-histogram-count {
      padding-inline-start: 16px;
    }
    .pr-histogram-label {
      padding-inline-end: 16px;
    }
  }

  //header third block
  .pr-review-snapshot-msq-container {
    padding: 20px 16px;
    margin: 0;
    flex: 1;
    @include container-shadow;
    background: $white;
    @include rounded-12;

    @include sm-desktop {
      display: grid;
      align-items: center;
    }

    .pr-msq-list.pr-review-snapshot-msq {
      margin: 0;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 8px;

      dt {
        margin-bottom: 8px;
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        h3 {
          @include text-xs-regular-uppercase;
          font-weight: $font-weight-regular;
        }
      }
      dd {
        padding: 0;
        margin: 0;
        .pr-filter-btn--button-separated.pr-filter-btn--active,
        .pr-filter-btn--button-separated:hover {
          box-shadow: 0 0 0 1px $fucsia2;
        }

        .pr-filter-btn:focus {
          box-shadow: 0 0 5px 1px $fucsia2;
        }

        .pr-filter-btn:after {
          width: auto;
        }

        .pr-filter-btn {
          @include rounded-24;
          border: unset;
          padding: 4px 10px;
          @include rounded-24;

          @include color-gradient-light;
          .pr-filter-btn__child.pr-filter-btn__buttonText,
          .pr-filter-btn__voteCount {
            padding: 0;
            border: unset;
            background: transparent;
            color: $dark;
            @include text-xs-regular-uppercase;
            @include ltr-only {
              letter-spacing: -0.02em;
            }
          }
          .pr-filter-btn__voteCount {
            padding-inline-start: 4px;
            &::before {
              content: "(";
            }
            &::after {
              content: ")";
            }
          }
        }
      }
    }
  }
}

#pr-reviewdisplay {
  scroll-margin: 50px;

  @include sm-desktop {
    width: 66.5%;
    margin: 0 auto;
  }

  .pr-review-snapshot-header-intro,
  .pr-snippet-read-and-write .pr-snippet-write-review-link,
  .pr-rd-main-header,
  .pr-rd-footer.pr-rd-content-block,
  .pr-review-display .pr-snippet-rating-decimal,
  .pr-rd-details.pr-rd-author-submission-date .pr-rd-bold,
  .pr-rd-details.pr-rd-author-nickname .pr-rd-bold,
  .pr-rd-details.pr-rd-author-location .pr-rd-bold,
  .pr-badging-icon,
  .pr-rd-to-top.pr-btn.pr-btn-default,
  .pr-review-translation__arrow,
  .pr-rd-details.pr-rd-reviewed-at .pr-rd-bold,
  .pr-review-snapshot-block.pr-review-snapshot-block-pros,
  .pr-review-snapshot-block.pr-review-snapshot-block-cons {
    display: none !important;
  }

  //IF POWER-REVIEW PUT THE FLAG INSTEAD OF THE LINK, REMOVE THIS RULE
  .pr-rd-details.pr-rd-reviewed-at {
    display: none;
  }

  .p-w-r {
    font-family: inherit;
  }
  .pr-rd-reviewer-details.pr-rd-inner-side-content-block {
    display: flex;
    gap: 8px;
  }

  .pr-review-snapshot-block-container.pr-review-snapshot-simple,
  .pr-rd-reviewer-details.pr-rd-inner-side-content-block,
  .pr-rd-reviewer-type.pr-rd-inner-side-content-block.pr-verified_buyer,
  .pr-rd-images.pr-rd-content-block {
    margin: 0;
  }
  .pr-review-snapshot-block-container .pr-review-snapshot-block {
    padding: 0;
    border: 0;
    min-height: 0;
  }

  //  Display Image reel
  .pr-irlsnippet {
    position: relative;
    margin: 32px -16px 0;
    @include sm-desktop {
      margin: 0 0 24px;
      display: flex;
      align-items: center;
    }

    .pr-irlsnippet-leftbtn {
      inset-inline-start: -20px;
    }
    .pr-irlsnippet-rightbtn {
      inset-inline-end: -20px;
    }

    .pr-irlsnippet-leftbtn,
    .pr-irlsnippet-rightbtn {
      display: none;

      @include sm-desktop {
        display: block;
        position: absolute;
        z-index: 1;
        width: 40px;
        height: 40px;
        @include glass-gradient;
        @include glass-shadow;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        &:disabled {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .pr-irlsnippet-images {
      display: flex;
      gap: 8px;

      @include sm-desktop {
        gap: 20px;
      }

      @include sm-desktop {
        margin: 0;
      }

      .pr-irlsnippet-image-container {
        background: transparent;
      }

      .pr-irlsnippet-image {
        @include rounded-12;
      }

      .pr-irlsnippet-image-container:first-child {
        margin-inline-start: 16px;
        @include sm-desktop {
          margin: 0;
        }
      }
      .pr-irlsnippet-image-container:last-child {
        margin-inline-end: 16px;
        @include sm-desktop {
          margin: 0;
        }
      }
    }
  }

  /**
  * STARS
  * Rating Stars can be customized by overriding the 
  * background-image property of the 5 Star-related
  * CSS classes
  * --------------------------------------------------
  * .pr-star-v4-0-filled    // Custom star image - filled 0%
  * .pr-star-v4-25-filled   // Custom star image - filled 25%
  * .pr-star-v4-50-filled   // Custom star image - filled 50%
  * .pr-star-v4-75-filled   // Custom star image - filled 75%
  * .pr-star-v4-100-filled  // Custom star image - filled 100%
  */

  .pr-review-snapshot-snippets .pr-snippet-stars-container {
    display: flex;
    justify-content: center;
  }
  .pr-review-snapshot-snippets .pr-snippet-stars.pr-snippet-stars-png {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 8px;
  }

  .p-w-r .pr-star-v4-0-filled,
  .p-w-r .pr-star-v4-25-filled,
  .p-w-r .pr-star-v4-50-filled,
  .p-w-r .pr-star-v4-75-filled,
  .p-w-r .pr-star-v4-100-filled {
    padding: 0;
    margin: 0;
    height: 23px;
    width: 23px;
    border-radius: 0;
    margin-inline-end: 0;

    html[dir="rtl"] & {
      transform: scaleX(-1);
    }
  }

  .pr-rd-star-rating {
    .pr-star-v4.pr-star-v4-0-filled,
    .pr-star-v4.pr-star-v4-25-filled,
    .pr-star-v4.pr-star-v4-50-filled,
    .pr-star-v4.pr-star-v4-75-filled,
    .pr-star-v4.pr-star-v4-100-filled {
      height: 15px;
      width: 15px;
    }
  }

  .p-w-r .pr-star-v4-0-filled {
    background-image: url("/next/assets/powerreviews-stars/star-0-filled.svg");
  }
  .p-w-r .pr-star-v4-25-filled {
    background-image: url("/next/assets/powerreviews-stars/star-25-filled.svg");
  }
  .p-w-r .pr-star-v4-50-filled {
    background-image: url("/next/assets/powerreviews-stars/star-50-filled.svg");
  }
  .p-w-r .pr-star-v4-75-filled {
    background-image: url("/next/assets/powerreviews-stars/star-75-filled.svg");
  }
  .p-w-r .pr-star-v4-100-filled {
    background-image: url("/next/assets/powerreviews-stars/star-100-filled.svg");
  }

  .pr-review-display .pr-snippet-stars {
    margin: 0;
  }

  .pr-snippet-rating-decimal,
  .pr-review-snapshot-snippets-headline {
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: 0;
    float: unset;
    @include text-xl-regular;
    color: $dark;
    width: auto;
  }

  .pr-review-snapshot .pr-review-snapshot-simple {
    justify-content: center;
  }

  .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
  }

  .pr-review-display .pr-rd-review-headline {
    @include text-s-bold-uppercase-cta;
  }

  .pr-review-display .pr-review {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 32px 0;
    display: grid;
    grid-template-columns: minmax(auto, max-content) 60%;
    column-gap: 12px;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $light-gray;
      position: absolute;
      top: 0;
    }
  }

  .pr-review-display.pr-rd-display-tablet .pr-review {
    grid-template-columns: minmax(auto, max-content) 70%;
  }

  .pr-review-display.pr-rd-display-desktop .pr-review {
    grid-template-columns: minmax(auto, max-content) 80%;
  }

  .pr-review-display .pr-rd-description .pr-rd-side-content-block {
    position: static;
    width: 100%;
    float: unset;
    padding: 0;
    display: flex;
    gap: 16px;
    margin: 0;
  }

  .pr-rd-description.pr-rd-content-block {
    width: auto;
    display: flex;
    flex-direction: column-reverse;
    position: static;
    margin: 0;
  }

  .pr-rd-header.pr-rd-content-block {
    display: flex;
    flex-direction: column;
    .pr-rd-review-headline {
      margin: 8px 0 0;
      color: inherit;
    }
  }

  .pr-rd-details.pr-rd-author-submission-date {
    position: absolute;
    top: 32px;
    inset-inline-end: 0;
  }

  .pr-rd-reviewer-type.pr-rd-inner-side-content-block.pr-verified_buyer .pr-rd-badging-text,
  .pr-rd-details.pr-rd-author-nickname,
  .pr-rd-details.pr-rd-author-location,
  .pr-rd-details.pr-rd-author-submission-date {
    padding: 0;
    margin: 0;
    @include text-xxs-regular;
    line-height: 140%;
  }

  .pr-review-display .pr-rd-side-content-block span {
    color: $dark;
  }
  .pr-rd-reviewer-type.pr-rd-inner-side-content-block.pr-verified_buyer .pr-rd-badging-text {
    color: $success;
  }

  .pr-rd-description-text {
    color: inherit;
    @include text-xs-regular;
    line-height: 140%;
    margin-bottom: 12px;
  }

  .pr-rd-reviewer-type.pr-rd-inner-side-content-block.pr-verified_buyer {
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 1px;
      height: 100%;
      background-color: $light-gray;
      position: absolute;
      inset-inline-start: -8px;
    }
  }

  .pr-rd-main-footer.pr-rd-main-footer-vertical-pagination {
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    text-align: center;

    .pr-rd-content-block {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .pr-rd-show-more.pr-btn.pr-btn-default {
        padding: 8px 16px;
        border: 0;
        background-color: $white;
        backdrop-filter: blur(10px);
        @include container-shadow;
        @include rounded-8;
        @include text-xs-bold-uppercase-cta;
        .pr-cross-icon {
          display: none;
        }
      }
    }
  }

  .pr-review-display .pr-rd-main-footer-vertical-pagination .pr-rd-pagination {
    margin: 0;
    padding: 0;
    border: 0;
  }

  .pr-review-display .pr-rd-no-reviews {
    position: relative;
    @include text-xs-regular;
    text-align: center;
  }

  .pr-review-display .pr-rd-no-reviews .pr-snippet-write-review-link {
    display: none;
  }

  .pr-review-translation {
    margin: 0;
    position: absolute;
    bottom: 32px;
    inset-inline-end: 0;
    .pr-review-translation__btn {
      color: inherit;
      @include text-xs-regular;
      @include text-underline;
    }
  }

  .pr-rd-images.pr-rd-content-block {
    display: flex;
    overflow-x: auto;
    grid-column: 1;
    grid-row: 1 / 3;
    width: 100%;
    scroll-snap-type: x mandatory;
    @include rounded-8;
    @include hide-scrollbar;

    .pr-rd-image-container.pr-rd-left {
      margin: 0;
      margin-inline-end: 12px;
      flex-shrink: 0;
      width: 100%;
      scroll-snap-align: start;
      @include rounded-8;
    }
  }

  .pr-review .pr-rd-content-block.pr-accordion {
    position: absolute;
    visibility: hidden;
    height: 0;
    width: 0;
  }
}

//Power reviews - write a review - style
#pr-write {
  .pr-alert-container,
  .pr-form-control-error-icon,
  .pr-form-group.form-group.pr-bottomline-form-group,
  .pr-form-group.form-group.pr-email_collection-form-group,
  .pr-form-group.form-group.pr-media_videourl-form-group,
  .pr-pull-right {
    display: none;
  }

  .p-w-r {
    font-family: inherit;
  }

  .pr-header-title,
  .pr-review-meter-text,
  .pr-control-label span,
  .p-w-r span,
  .p-w-r p,
  .p-w-r a {
    color: $dark;
  }

  .p-w-r input,
  .p-w-r textarea {
    @include rounded-8;
  }

  .p-w-r .pr-star-v4-0-filled {
    background-image: url("/next/assets/powerreviews-stars/star-0-filled.svg");
  }
  .p-w-r .pr-star-v4-25-filled {
    background-image: url("/next/assets/powerreviews-stars/star-25-filled.svg");
  }
  .p-w-r .pr-star-v4-50-filled {
    background-image: url("/next/assets/powerreviews-stars/star-50-filled.svg");
  }
  .p-w-r .pr-star-v4-75-filled {
    background-image: url("/next/assets/powerreviews-stars/star-75-filled.svg");
  }
  .p-w-r .pr-star-v4-100-filled {
    background-image: url("/next/assets/powerreviews-stars/star-100-filled.svg");
  }

  .p-w-r input:focus + label.pr-star-v4 {
    background-color: transparent;
  }
  .pr-btn-fileinput,
  .pr-accessible-btn {
    color: $dark;
    padding: 8px 16px;
    border: 0;
    background-color: $white;
    backdrop-filter: blur(10px);
    @include container-shadow;
    @include rounded-8;
    @include text-xs-bold-uppercase-cta;
  }
  .pr-btn-fileinput svg g circle {
    fill: $white;
  }
  .pr-file-input-btn-group {
    justify-content: center;
  }
  .pr-footer {
    padding: 0;
    border: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .thank-you-page {
    .header,
    .review-preview {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .header {
      margin: 10px 15px;
      padding: 0;
    }
    .review-preview .col-sm-12:first-child {
      display: flex;
    }
    .col-sm-12 {
      width: auto;
    }
    .subtitle,
    .title {
      color: $dark;
      text-align: center;
    }
    h5 a {
      color: $dark;
      @include text-underline;
    }
    .pr-rating-number {
      border: 0;
    }
  }

  input::placeholder,
  textarea::placeholder {
    color: $dark;
    opacity: 1; /* Firefox */
  }
}

#st-faq {
  position: relative;
  z-index: 1;
}

// Power Reviews category snippet
#pr-categorysnippet {
  .pr-category-snippet__total.pr-category-snippet__item {
    display: none;
  }

  .p-w-r .pr-snippet-rating-decimal {
    display: none;
  }

  .p-w-r .pr-snippet-stars {
    background: none;
  }

  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star,
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-0-filled,
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-100-filled,
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-25-filled,
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-50-filled,
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-accessible-75-filled,
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-half,
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-selected,
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4,
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-0-filled,
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-100-filled,
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-25-filled,
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-50-filled,
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4-75-filled {
    width: 12px;
    height: 12px;
    margin-inline: 0;
    padding-inline: 0;
    background-position: 50% 50%;
    background-size: cover;

    html[dir="rtl"] & {
      transform: scaleX(-1);
    }
  }

  .p-w-r .pr-star-v4-0-filled {
    background-image: url("/next/assets/powerreviews-stars/star-0-filled.svg");
  }
  .p-w-r .pr-star-v4-25-filled {
    background-image: url("/next/assets/powerreviews-stars/star-25-filled.svg");
  }
  .p-w-r .pr-star-v4-50-filled {
    background-image: url("/next/assets/powerreviews-stars/star-50-filled.svg");
  }
  .p-w-r .pr-star-v4-75-filled {
    background-image: url("/next/assets/powerreviews-stars/star-75-filled.svg");
  }
  .p-w-r .pr-star-v4-100-filled {
    background-image: url("/next/assets/powerreviews-stars/star-100-filled.svg");
  }

  .p-w-r input:focus + label.pr-star-v4 {
    background-color: transparent;
  }
}
