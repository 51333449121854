@use "styles" as *;

.iconContainer {
  position: relative;
  display: flex;

  .iconRtl {
    transform: scaleX(-1);
  }

  .iconValue {
    @include color-gradient;
    @include text-3xs-bold;

    position: relative;
    margin-top: auto;
    margin-inline-start: -6px;
    border-radius: 999px;
    padding: 2px 3px;
    min-width: 12px;
    max-width: 26px;
    height: 12px;
    text-overflow: ellipsis;
    line-height: 1em;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }
}
