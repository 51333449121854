@use "styles" as *;

.pagePopup {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  inset: 0;
  inset-block-start: $header-mobile + 12px;
  z-index: 99999;
  transition: transform 0.3s ease;
  will-change: transform;
  pointer-events: none;

  @include sm-desktop {
    inset-block-start: $header-desktop - $header-nav;
  }
}

.panel {
  position: relative;
  pointer-events: all;
  padding: 1rem;
  grid-column: span 12;
  @include container-shadow;

  @include sm-desktop {
    grid-column: 9 / span 4;
  }

  @include md-desktop {
    grid-column: 10 / span 3;
  }
}

.scrolledTop {
  transform: translateY(-#{$header-topbar-mobile});

  @include sm-desktop {
    transform: translateY(-#{$header-topbar-desktop});
  }
}

.scrolledTotal {
  transform: translateY(-#{$header-mobile});

  @include sm-desktop {
    transform: translateY(-#{$header-desktop - $header-nav});
  }
}

.closeButton {
  position: absolute;
  inset-inline-end: 16px;
  inset-block-start: 16px;
  z-index: 1;
}
